
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout/Layout';
import { StaticImage } from "gatsby-plugin-image"
import SplitSection from '../../components/SplitSection';

import LeadForm from '../../components/LeadForm';

const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/products`,

  },


];

const seo={
  title: "Soluciones Industria Alimenticia",
  desc:  "Las mejores soluciones para la industria alimenticia",
  image: "soluciones-industria-alimenticia.jpg",
  pathname: "/industria-alimenticia", 
}


export default function Named ({location}) {

  return(
  <Layout location={location} crumbLabel="Industria Alimenticia"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>





<section id="productos" className="mt-14">
              <div className="">
               <h1 className="text-5xl font-semibold text-center mb-2">Indutria Alimenticia</h1>
                <h2 className="text-4xl font-semibold text-center mb-8 text-primary-lighter">Soluciones pensadas para el bienestar de sus colaboradores y clientes</h2>
              </div>
            </section>






<section id="productos" className="mt-14">
<div className="relative bg-blue-900">
  <div className="h-56 bg-blue-900 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../../images/soluciones-industria-alimenticia.jpg"
                      className="flex justify-center "
                      alt="Productos de alta calidad para exportación"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-28">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <p className=" text-xl text-leftfont-normal text-white pb-6">Sabemos que necesita un proceso de inocuidad alimentaria eficiente en su empresa y para sus clientes, por eso somos su mejor aliado, contamos con los productos y asesorías adecuadas que le garantizan la implementación de procedimientos de limpieza prácticos y sencillos que le apoyan en su operación. En Unilimpio encuentra las mejores soluciones de higiene de acuerdo a los más altos estándares de calidad del mercado.</p>
    </div>
  </div>
</div>
</section>



<section id="categorias productos" className=" mt-20 pb-10">
  <h2 className="text-primary-default  title-font text-center text-5xl font-semibold  ">Portafolio recomendado </h2>
         
    <div className="relative bg-white px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8">
     <div className="relative max-w-7xl mx-auto mt-10">
      <div className=" max-w-lg mx-auto grid gap-8 lg:grid-cols-4 lg:max-w-none">

        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1">
              <StaticImage src="../images/categoria-productos-quimicos.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra amplia línea de dispensadores"
                    placeholder="blurred"
                    layout="constrained"/>

                    

                <div className="flex flex-wrap mx-auto justify-center">
                <h3 className="text-3xl font-semibold text-secondary-lighter text-center pb-4">Productos Químicos </h3>
                </div>
                <Link   to="/productos/quimicos/amonio-cuaternario-de-quinta-generacion-ozz" className="text-primary-lighter inline-flex items-center">Amonio Cuaternario al 10%
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link to="/productos/quimicos/desinfectante-con-amonio-cuaternario" className="text-primary-lighter inline-flex items-center">
              Desinfectante con Amonio Cuaternario Ozz
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link  to="/productos/quimicos/alcohol-multipropositos" className="text-primary-lighter inline-flex items-center">
              Alcohol al 72%
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link  to="/productos/quimicos/cloro-liquido-ozz" className="text-primary-lighter inline-flex items-center">
              Cloro líquido al 5.5%
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link  to="/productos/quimicos/desinfectante-liquido" className="text-primary-lighter inline-flex items-center">
              Desinfectante líquido 
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link> <br/>

          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <StaticImage src="../images/categoria-absorbentes-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos profesionales para higiene de manos"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center pb-4">Inocuidad Alimentaria</h3>
                </div>
                <Link   to="/productos/quimicos/digrizz-ozz" className="text-primary-lighter inline-flex items-center">
              Detergente Concentrado Multiusos Digrizz Ozz
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/quimicos/lavavajilla-neutro" className="text-primary-lighter inline-flex items-center">
              Lavavajilla Neutro Ozz 
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link  to="/productos/quimicos/limpiador-de-areas-frias" className="text-primary-lighter inline-flex items-center">
              Limpiador de Áreas FrÍas 
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/quimicos/limpiador-de-hornos-y-parrillas-en-caliente" className="text-primary-lighter inline-flex items-center">
             Limpiador de Hornos y Parrillas en Caliente 
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/quimicos/limpiador-desincrustante-acido-ozz" className="text-primary-lighter inline-flex items-center">
              Limpiador Desincrustante Ácido Ozz
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/quimicos/limpiador-removedor-caustico" className="text-primary-lighter inline-flex items-center">
              Limpiador Removedor Cáustico  
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/quimicos/sanitizante-de-superficies-bacterac-ozz" className="text-primary-lighter inline-flex items-center">
              Sanitizante de Superficies Duras Bacterac de Grado Alimenticio  
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/> 
          <Link  to="/productos/quimicos/sanitizante-peracetico" className="text-primary-lighter inline-flex items-center">
              Sanitizante peracético al 15%
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/quimicos/sanitizante-y-desinfectante-organic-100" className="text-primary-lighter inline-flex items-center">
              Sanitizante y Desinfectante Organic 100 
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <StaticImage src="../images/categoria-absorbentes-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos profesionales para higiene de manos"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center">
                  <h3 to="/productos/absorbentes" className="text-3xl font-semibold text-secondary-lighter text-center pb-4">Absorbentes</h3>
                </div>
                <Link to="/productos/absorbentes/toalla-z-interfoliada-bio" className="text-primary-lighter inline-flex items-center">
              Toalla Z Bio Interfoliada
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link  to="/productos/absorbentes/toalla-de-manos-bio" className="text-primary-lighter inline-flex items-center">
             Toalla de Manos Bio x2 unidades  
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link  to="/productos/absorbentes/toalla-de-manos-center-pull" className="text-primary-lighter inline-flex items-center">
            Toalla de Manos Center Pull
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link  to="/productos/absorbentes/limpion-industrial" className="text-primary-lighter inline-flex items-center">
            Limpión Industrial 
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/absorbentes/papel-higienico-jumbo-bio" className="text-primary-lighter inline-flex items-center">
              Papel Higiénico Jumbo Bio
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/absorbentes/servilleta-bio" className="text-primary-lighter inline-flex items-center">
             Servilleta Bio  
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/absorbentes/servilleta-dispensada" className="text-primary-lighter inline-flex items-center">
             Servilleta en Z Dispensada  
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../images/categoria-higiene-de-manos-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos de alta calidad para la industria alimenticia"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 to="/productos/higiene-de-manos/" className="text-3xl font-semibold text-secondary-lighter text-center pb-4">Higiene de Manos</h3>
                </div>
                <Link to="/productos/higiene-de-manos/jabon-liquido-para-relleno" className="text-primary-lighter  inline-flex items-center">
              Jabón Líquido Antibacterial para Relleno
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/higiene-de-manos/jabon-liquido-en-sachet" className="text-primary-lighter  inline-flex items-center">
              Jabón Líquido Antibacterial en Sachet para Dispensador 
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link  to="/productos/higiene-de-manos/gel-antibacterial-para-relleno" className="text-primary-lighter inline-flex items-center text-left">
             Gel Antibacterial para Relleno
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link  to="/productos/higiene-de-manos/gel-antibacterial-en-sachet" className="text-primary-lighter inline-flex items-center text-left">
              Gel Antibacterial en Sachet para Dispensador 
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link  to="/productos/higiene-de-manos/alcohol-antimicrobiano" className="text-primary-lighter inline-flex items-center">
              Alcohol Antimicrobiano
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../images/categoria-lavanderia-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestros productos de alta calidad para la desinfección de superficies"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 to="/productos/lavanderia/" className="text-3xl font-semibold text-secondary-lighter text-center pb-4">Lavandería </h3>
                </div>
                <Link   to="/productos/lavanderia/detergente-en-polvo" className="text-primary-lighter inline-flex items-center">
              Detergente en Polvo Unilimpio
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/lavanderia/detergente-liquido-enzimatico" className="text-primary-lighter inline-flex items-center">
              Detergente Líquido Enzimático
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../images/categoria-dispensadores-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra línea de absorbentes"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 to="/productos/dispensadores/" className="text-3xl font-semibold text-secondary-lighter text-center pb-4">Dispensadores </h3>
                </div>
                <Link   to="/productos/dispensadores/jabon-liquido" className="text-primary-lighter inline-flex items-center">Jabón Líquido
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/dispensadores/jabon-espuma" className="text-primary-lighter inline-flex items-center">Jabón Espuma 
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/dispensadores/gel-antibacterial" className="text-primary-lighter inline-flex items-center">Gel Antibacterial
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/dispensadores/papel-higienico-jumbo-unilimpio" className="text-primary-lighter inline-flex items-center">Papel Higiénico Jumbo Unilimpio
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/dispensadores/toalla-z-unilimpio" className="text-primary-lighter inline-flex items-center">Toalla en Z Unilimpio
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/dispensadores/toalla-center-pull" className="text-primary-lighter inline-flex items-center">Toalla Center Pull 
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../images/categoria-secadores-de-manos-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra línea de absorbentes"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 to="/productos/secadores-de-manos/" className="text-3xl font-semibold text-secondary-lighter text-center pb-4">Secadores de Manos </h3>
                </div>
                <Link   to="/productos/secadores-de-manos/expair-automatico" className="text-primary-lighter inline-flex items-center">
          Secador de Manos Expair Automático
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link  to="/productos/secadores-de-manos/supair-automatico" className="text-primary-lighter inline-flex items-center">
          Secador de Manos Supair Automático
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../images/categoria-linea-profesional-ecuador.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Descubra nuestra línea de absorbentes"
                    placeholder="blurred"
                    layout="constrained" />
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 to="/productos/linea-profesional/" className="text-3xl font-semibold text-secondary-lighter text-center pb-4">Línea profesional </h3>
                </div>
                <Link   to="/productos/linea-profesional/mopa-polvo-acrilica" className="text-primary-lighter inline-flex items-center">
          Mopa Polvo Acrílica
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/linea-profesional/mopa-humeda-microfibra-velcro" className="text-primary-lighter inline-flex items-center">
          Mopa Húmeda Microfibra Velcro
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/>
          <Link   to="/productos/linea-profesional/mopa-vertical-secado" className="text-primary-lighter inline-flex items-center">
          Mopa Vertical Secado
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/> 
          <Link  to="/productos/linea-profesional/coche-exprimidor" className="text-primary-lighter inline-flex items-center">
              Coche Exprimidor
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/> 
          <Link   to="/productos/linea-profesional/panos-microfibra" className="text-primary-lighter inline-flex items-center">
              Paños Microfibra
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/> 
          <Link   to="/productos/linea-profesional/escurridor-para-vidrio" className="text-primary-lighter inline-flex items-center">
              Escurridor para Vidrio
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/> 
          <Link   to="/productos/linea-profesional/escurridor-de-piso" className="text-primary-lighter inline-flex items-center">
              Escurridor de Piso
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link><br/> 
          </div> 
        </div>
      </div>

     

     </div>
    </div>
  </div>
</section>



    
<LeadForm/>
  
</Layout>
)}

